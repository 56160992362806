<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid"
      v-bind:style="{
        'background-image': 'url(' + backgroundImage + ')',
        'background-size': 'cover'
      }"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="margin-right: 250px;"
      ></div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <div
            class="card"
            style="width: 446px; height: auto; border-radius: 20px; padding: 60px 50px;"
          >
            <!--begin::Signin-->
            <div class="login-form login-signin">
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
              >
                <div class="pb-13 pt-lg-0 pt-5">
                  <h3
                    class="font-weight-bolder font-size-h4 font-size-h1-lg"
                    style="color: #3532C4;"
                  >
                    Login
                  </h3>
                  <h5 class="mt-4">
                    Halo, Selamat Datang👋🏻
                  </h5>
                </div>
                <div class="form-group">
                  <label
                    class="font-size-h6 font-weight-bolder"
                    style="color: #3532C4;"
                    >Email</label
                  >
                  <div class="form-input-login">
                    <v-text-field
                      class="rounded-lg"
                      type="email"
                      name="email"
                      ref="email"
                      :rules="[rules.emailRules]"
                      v-model="form.email"
                      solo
                    ></v-text-field>
                  </div>
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between">
                    <label
                      class="font-size-h6 font-weight-bolder"
                      style="color: #3532C4;"
                      >Password</label
                    >
                  </div>
                  <div class="form-password">
                    <v-text-field
                      class="rounded-lg"
                      v-model="form.password"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.min]"
                      :type="show ? 'text' : 'password'"
                      name="input-10-1"
                      hint="Setidaknya 8 karakter"
                      counter
                      solo
                      @click:append="show = !show"
                    ></v-text-field>
                  </div>
                </div>
                <div class="pb-lg-2 pb-4 text-center">
                  <button
                    ref="kt_login_signin_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 py-4"
                    style="padding: 0 150px; border-radius: 10px; background-color: #316BF6;"
                  >
                    Login
                  </button>
                </div>
                <div>
                  <span class="font-weight-bold font-size-h4"
                    >Belum punya akun?
                    <a
                      id="kt_login_signup"
                      @click="showForm('signup')"
                      style="color: #2541B2;"
                      >Buat akun</a
                    ></span
                  >
                </div>
              </form>
            </div>
            <!--end::Signin-->
            <!--begin::Signup-->
            <div class="login-form login-signup">
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signup_form"
              >
                <div class="pb-13 pt-lg-0 pt-5">
                  <h3
                    class="font-weight-bolder font-size-h4 font-size-h1-lg"
                    style="color: #3532C4;"
                  >
                    Sign Up
                  </h3>
                  <h5 class="mt-4">
                    Halo, Selamat Datang👋🏻
                  </h5>
                </div>
                <div class="form-group">
                  <label
                    class="font-size-h6 font-weight-bolder"
                    style="color: #3532C4;"
                    >Nama</label
                  >
                  <div class="form-input-login">
                    <v-text-field
                      class="rounded-lg"
                      v-model="form.user_fullname"
                      solo
                    ></v-text-field>
                  </div>
                </div>
                <div class="form-group">
                  <label
                    class="font-size-h6 font-weight-bolder"
                    style="color: #3532C4;"
                    >Email</label
                  >
                  <div class="form-input-login">
                    <v-text-field
                      class="rounded-lg"
                      type="email"
                      name="email"
                      ref="email"
                      :rules="[rules.emailRules]"
                      v-model="form.email"
                      solo
                    ></v-text-field>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label
                    class="font-size-h6 font-weight-bolder"
                    style="color: #3532C4;"
                    >Divisi</label
                  >
                  <div class="form-input-login">
                    <v-select
                      v-model="form.division_id"
                      id="division_id"
                      :items="data_division_combobox"
                      @click="dataDivision_Combobox"
                      item-value="division_id"
                      item-text="division_name"
                      class="vuetify-custom rounded-lg"
                      type="text"
                      outlined
                      solo
                      v-bind:style="{
                        color: '#F3F6F9 !important'
                      }"
                    ></v-select>
                  </div>
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between">
                    <label
                      class="font-size-h6 font-weight-bolder"
                      style="color: #3532C4;"
                      >Password</label
                    >
                  </div>
                  <div class="form-password">
                    <v-text-field
                      class="rounded-lg"
                      v-model="form.password"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.min]"
                      :type="show ? 'text' : 'password'"
                      name="input-10-1"
                      hint="Setidaknya 8 karakter"
                      counter
                      solo
                      @click:append="show = !show"
                    ></v-text-field>
                  </div>
                </div>
                <div class="form-group">
                  <label class="checkbox mb-0 font-weight-bolder">
                    <input type="checkbox" name="agree" />
                    <span class="mr-2"></span>
                    Saya setuju dengan
                    <a href="#" class="ml-2" style="color: #2541B2;"
                      >Syarat & Ketentuan</a
                    >.
                  </label>
                </div>
                <div class="pb-lg-2 pb-4 text-center">
                  <button
                    ref="kt_login_signup_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 py-4"
                    style="padding: 0 140px; border-radius: 10px; background-color: #316BF6;"
                  >
                    Sign Up
                  </button>
                </div>
                <div>
                  <span class="font-weight-bold font-size-h4"
                    >Sudah punya akun?
                    <a
                      id="kt_login_signup"
                      @click="showForm('signin')"
                      style="color: #2541B2;"
                      >Login</a
                    ></span
                  >
                </div>
              </form>
            </div>
            <!--end::Signup-->
          </div>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      data_division_combobox: [],
      form: {
        user_fullname: "",
        email: "",
        division_id: "",
        division_name: "",
        password: ""
      },
      show: false,
      rules: {
        min: v => v.length >= 8 || "Min 8 Karakter",
        emailRules: v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Masukkan email dengan benar",
        emailMatch: () => "Email dan Password yang kamu masukkan tidak sama."
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return this.state == "signin"
        ? process.env.BASE_URL + "assets/bg_login_new.png"
        : process.env.BASE_URL + "assets/bg_login_new.png";
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email harus diisi"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password harus diisi"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        user_fullname: {
          validators: {
            notEmpty: {
              message: "Nama harus diisi"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email harus diisi"
            }
          }
        },
        division_id: {
          validators: {
            notEmpty: {
              message: "Divisi harus diisi"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password harus diisi"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Konfirmasi password harus diisi"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "Password dan konfirmasi password tidak sama"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => this.$router.push({ name: "dashboard" }))
          .catch(() => {});

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });

    this.fv1.on("core.form.valid", () => {
      const user_fullname = this.form.user_fullname;
      const email = this.form.email;
      const division_id = this.form.division_id;
      const password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, {
            user_fullname: user_fullname,
            email: email,
            division_id: division_id,
            password: password
          })
          .then(() => this.$router.push({ name: "dashboard" }))
          .catch(() => {});

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  },

  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    dataDivision_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: "R",
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Division",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_division_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    }
  }
};
</script>
